import {graphql, useStaticQuery} from 'gatsby';
import {Strapi_Serie} from '../../typegen/graphql';

const useSeriesData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      series(sort: "date:DESC") {
        ...Series
      }
    }
  }
`);

  return data.strapi.series as Strapi_Serie[];
};

export default useSeriesData;
