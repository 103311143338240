import {useLocation} from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import React from 'react';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {PageContent} from '../components/Layout';
import Thumbnail from '../components/Thumbnail';
import {Appear, ThumbnailContainer} from '../components/atoms';
import useSeriesData from '../queries/useSeriesData';
import {NavigationContext} from '../services/navigation';
import {getYear} from '../utils/getYear';

const Container = styled(PageContent)`
  display: flex;
`;

const Series: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {state} = useLocation();
  const ref = React.useRef();
  const allSeries = useSeriesData();
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
    }
  `)
  return (
    <Container ref={ref}>
      <Helmet
        title={`Series | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: 'Series for which Stavros Makronis composed the music',
          },
          {
            name: 'keywords',
            content: 'series, score, music, Stavros Markonis',
          },
          {
            property: `og:title`,
            content: `Series | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: 'Series for which Stavros Makronis composed the music',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Series | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content:  'Series for which Stavros Makronis composed the music',
          },
        ]}
      />
      <Appear>
        <ThumbnailContainer translation={(state as any)?.enableTranslationTransition}>
          {allSeries.map(series => (
            <Thumbnail
              key={series.id}
              image={getImage((series.thumbnail as any).file)}
              title={`${series.title}`}
              date={getYear(series)}  
              to={series.slug}
              state={{enableTranslationTransition: true}}
            />
          ))}
        </ThumbnailContainer>
      </Appear>
    </Container>
  );
}

export default Series;
